import React from 'react';
import IwocaImage from '../IwocaImage';

import styles from './ContactPersonal.module.scss';

class ContactPersonal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activePicture: 1
        };

        const { profilePictures } = this.props;

        // Clone to be able to make smoother transitions
        this.profilePictures = profilePictures.concat(profilePictures);
        this.loopProfilePicture = this.loopProfilePicture.bind(this);
        this.getImageClass = this.getImageClass.bind(this);
    }

    componentDidMount() {
        if (window.DISABLE_ANIMATIONS !== true) {
            this.carouselInterval = setInterval(
                this.loopProfilePicture,
                this.props.loopInterval
            );
        }
    }

    componentWillUnmount() {
        clearInterval(this.carouselInterval);
    }

    loopProfilePicture() {
        this.setState((prevState) => {
            const nextState =
                prevState.activePicture + 1 < this.profilePictures.length
                    ? prevState.activePicture + 1
                    : 0;
            return { activePicture: nextState };
        });
    }

    getImageClass(index) {
        const { profilePictures } = this;
        const { activePicture } = this.state;

        let imageClass = '';
        switch (activePicture - index) {
            case 0:
                imageClass = styles.contactPersonalItem__active;
                break;
            case -1:
            case profilePictures.length - 1:
                imageClass = styles.contactPersonalItem__next;
                break;
            case 1:
            case -profilePictures.length + 1:
                imageClass = styles.contactPersonalItem__prev;
                break;
            case -2:
            case profilePictures.length - 2:
                imageClass = styles.contactPersonalItem__fadeIn;
                break;
            case 2:
            case -profilePictures.length + 2:
                imageClass = styles.contactPersonalItem__fadeOut;
                break;
            default:
                imageClass = styles.contactPersonalItem;
                break;
        }
        return imageClass;
    }

    render() {
        const { profilePictures } = this;

        if (profilePictures.length === 0) {
            console.error(
                'Contact Persons: Please provide at least one contact person picture'
            );
            return null;
        }

        return (
            <ul className={styles.contactPersonal}>
                {profilePictures.map((item, index) => (
                    <li key={index} className={this.getImageClass(index)}>
                        <IwocaImage
                            src={item.profilePictureImage.file.url}
                            alt={item.altText}
                            className={styles.contactPersonalImage}
                            rounded
                        />
                    </li>
                ))}
            </ul>
        );
    }
}

ContactPersonal.defaultProps = {
    loopInterval: 1750
};

export default ContactPersonal;
