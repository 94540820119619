/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */

import React from 'react';
import ReactMarkdown from 'react-markdown';
import ContactPersonal from './ContactPersonal';
import styles from './ContactSection.module.scss';

function ContactSection({ data }) {
    return (
        <section className={styles.contactSection}>
            <div className={styles.container}>
                <ContactPersonal profilePictures={data.profilePictures} />
                <div className={styles.titleBox}>
                    <h2 className="title">{data.title}</h2>
                    {data.subtitleText && (
                        <ReactMarkdown
                            className="subtitle"
                            source={data.subtitleText.subtitle}
                        />
                    )}
                </div>

                <div className={styles.links}>
                    {data.contactLinks.map((link) => (
                        <a
                            key={link.id}
                            href={link.url}
                            className={styles.contactLink}>
                            {link.contactMethodIcon && (
                                <img
                                    className={styles.contactLinkIcon}
                                    src={link.contactMethodIcon.file.url}
                                    alt={link.name}
                                />
                            )}
                            <span>{link.linkText}</span>
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ContactSection;
