import React from 'react';
import Slider from 'react-slick';
import styles from './PartnerCarouselSection.module.scss';

class PartnerCarouselSection extends React.Component {
    constructor() {
        super();
        this.state = {
            oldCategoryIndex: 0,
            selectedCategoryIndex: 0,
            fadeTransition: false
        };
    }

    onClickCategory(categoryIndex) {
        this.setState(
            (prevState) => ({
                oldCategoryIndex: prevState.selectedCategoryIndex,
                selectedCategoryIndex: categoryIndex,
                fadeTransition:
                    prevState.selectedCategoryIndex !== categoryIndex
            }),
            () => {
                setTimeout(() => this.setState({ fadeTransition: false }), 500);
            }
        );
    }

    render() {
        const { data } = this.props;
        const {
            oldCategoryIndex,
            selectedCategoryIndex,
            fadeTransition
        } = this.state;

        const oldCategory =
            data.partnerCategory && data.partnerCategory.length > 0
                ? data.partnerCategory[oldCategoryIndex]
                : null;
        const selectedCategory =
            data.partnerCategory && data.partnerCategory.length > 0
                ? data.partnerCategory[selectedCategoryIndex]
                : null;

        const categoryToShow = fadeTransition ? oldCategory : selectedCategory;

        const sliderSettings = {
            nextArrow: <SliderArrow image={data.rightChevron.file.url} />,
            prevArrow: <SliderArrow image={data.leftChevron.file.url} prev />,
            slidesToShow: 1,
            centerMode: true,
            variableWidth: true,
            speed: 800,
            dotsClass: styles.dots,
            className: fadeTransition
                ? `${styles.slider} ${styles.fadeOut}`
                : styles.slider,
            responsive: [
                {
                    breakpoint: 599,
                    settings: {
                        dots: true,
                        arrows: false
                    }
                }
            ]
        };

        return (
            <div className={styles.partnerCarousel}>
                <div className={styles.categoriesContainer}>
                    <div className={styles.categories}>
                        {data.partnerCategory &&
                            data.partnerCategory.map(
                                (partnerCategory, index) => (
                                    <button
                                        key={partnerCategory.id}
                                        className={
                                            selectedCategoryIndex === index
                                                ? `${styles.category} ${styles.categorySelected}`
                                                : styles.category
                                        }
                                        onClick={() =>
                                            this.onClickCategory(index)
                                        }
                                        type="button">
                                        {partnerCategory.title}
                                    </button>
                                )
                            )}
                    </div>
                </div>
                {categoryToShow && (
                    <div
                        className={
                            categoryToShow.partnerBoxes &&
                            categoryToShow.partnerBoxes.length === 1
                                ? styles.categorySingle
                                : ''
                        }>
                        <Slider {...sliderSettings}>
                            {categoryToShow.partnerBoxes &&
                                categoryToShow.partnerBoxes.map(
                                    (partnerBox) => (
                                        <div
                                            key={partnerBox.id}
                                            className={styles.partner}>
                                            <div
                                                className={styles.logo}
                                                style={
                                                    partnerBox.logoImage && {
                                                        backgroundImage: `url("${partnerBox.logoImage.file.url}")`
                                                    }
                                                }
                                            />
                                            <div
                                                className={
                                                    styles.textContainer
                                                }>
                                                <div
                                                    className={
                                                        styles.textInnerContainer
                                                    }>
                                                    <h4
                                                        className={
                                                            styles.title
                                                        }>
                                                        {partnerBox.title}
                                                    </h4>
                                                    {partnerBox.description && (
                                                        <p
                                                            className={
                                                                styles.description
                                                            }
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    partnerBox
                                                                        .description
                                                                        .description
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                        </Slider>
                    </div>
                )}
            </div>
        );
    }
}

const SliderArrow = ({ image, prev, onClick }) => {
    const arrowType = prev !== undefined ? styles.arrowPrev : styles.arrowNext;
    return (
        <img
            src={image}
            alt="arrow"
            className={`${styles.arrow} ${arrowType}`}
            onClick={onClick}
        />
    );
};

export default PartnerCarouselSection;
