import React from 'react';
import classnames from 'classnames';
import { checkIfChrome } from 'aphrodite-shared/util/helpers';
import styles from './IwocaImage.module.scss';
import { canUseWebP } from './IwocaImageHelper';

/**
 *
 * @class IwocaImage
 * @extends {React.Component}
 */

class IwocaImage extends React.Component {
    constructor(props) {
        super(props);
        this.iwocaImageHd = null;
    }

    componentDidMount() {
        // If supported generate a webp link
        const webpSupport = canUseWebP();
        const hdSource = webpSupport
            ? window.DISABLE_ANIMATIONS !== true
                ? `${this.props.src}?fm=webp`
                : `${this.props.src}?fm=jpg`
            : `${this.props.src}?q=70`;

        const hdLoaderImg = new Image();

        // Fetch the HD data
        hdLoaderImg.src = hdSource;

        // When HD data is fetched trigger onload, which sets the attributes on the image, which is connected using refs
        hdLoaderImg.onload = () => {
            this.iwocaImageHd.setAttribute(
                'style',
                `background-image: url('${hdSource}')`
            );
            this.iwocaImageHd.classList.add(styles.fadeIn);
        };
    }

    render() {
        const { rounded, className, noPlaceholder } = this.props;

        const imageContainerClassName = classnames(
            styles.imageContainer,
            className
        );
        const lowResImageStyle = classnames(
            styles.lowRes,
            rounded ? styles.rounded : null
        );
        const highResImageStyle = classnames(
            styles.highRes,
            rounded ? styles.rounded : null
        );

        return (
            <div className={imageContainerClassName}>
                {!noPlaceholder && (
                    <div
                        className={lowResImageStyle}
                        style={
                            checkIfChrome()
                                ? {
                                      backgroundImage: `url('${this.props.src}?w=10')`
                                  }
                                : {
                                      backgroundImage: `url('${this.props.src}?q=70')`
                                  }
                        }
                    />
                )}
                <div
                    className={highResImageStyle}
                    ref={(imageLoadedElem) => {
                        this.iwocaImageHd = imageLoadedElem;
                    }}
                />
            </div>
        );
    }
}

IwocaImage.defaultProps = {
    noPlaceholder: false
};

export default IwocaImage;
