import React from 'react';
import { graphql } from 'gatsby';

import styles from './CarouselPage.module.scss';
import iwocaPageStyles from '../../uk/iwocaPage.module.scss';
import MetaData from '../../../components/Layout/MetaData';
import NavBar from '../../../components/NavBar/NavBar';
import Footer from '../../../components/Footer/Footer';
import HeroBannerSmall from '../../../components/common/HeroBanner/HeroBannerSmall';
import PartnerCarouselSection from '../../../components/common/carousel/PartnerCarouselSection';
import ContactSection from '../../../components/common/ContactSection/ContactSection';

const QuickJump = ({ data }) => {
    const { contentfulPageCarousel: pageData } = data;
    const {
        title,
        metaDescription,
        allowSearchEngineScraping = true,
        url,
        pageContent
    } = pageData;

    return (
        <div className={iwocaPageStyles.Page}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url
                }}
            />

            <NavBar />

            <div className={styles.wrapper}>
                {pageContent.sections.map((section, index) => {
                    switch (section.__typename) {
                        case 'ContentfulSectionHeroBannerSmall':
                            return (
                                <HeroBannerSmall
                                    key={index}
                                    data={section}
                                    currentPage={pageData}
                                />
                            );
                        case 'ContentfulSectionPartnerCarousel':
                            return (
                                <PartnerCarouselSection
                                    key={index}
                                    data={section}
                                />
                            );

                        case 'ContentfulSectionContact':
                            return (
                                <div
                                    className="contact-section"
                                    key={index}
                                    minHeight="439px">
                                    <ContactSection data={section} />
                                </div>
                            );

                        default:
                            return null;
                    }
                })}
            </div>
            <Footer />
        </div>
    );
};

export const query = graphql`
    query CarouselPageQuery($id: String!) {
        contentfulPageCarousel(id: { eq: $id }) {
            title
            url
            metaDescription
            allowSearchEngineScraping
            pageContent {
                sections {
                    __typename
                    ... on ContentfulSectionHeroBannerSmall {
                        shortTitle
                        shortTitleMenu {
                            menuList {
                                ... on ContentfulLink {
                                    id
                                    title
                                    url
                                    icon {
                                        file {
                                            url
                                        }
                                    }
                                }
                                url
                            }
                        }
                        headline
                        subtitleText: subtitle {
                            subtitle
                        }
                        content {
                            __typename
                            ... on ContentfulHeroImage {
                                keyword
                                image {
                                    file {
                                        url
                                    }
                                }
                                highlightColour
                            }
                        }
                        button {
                            ... on ContentfulButton {
                                id
                                dataGaId
                                buttonText
                                url
                                icon {
                                    file {
                                        url
                                    }
                                }
                                isDownloadable
                            }
                        }
                        selectTextAlignment
                        selectTextColourScheme
                    }
                    ... on ContentfulSectionPartnerCarousel {
                        title
                        partnerCategory {
                            id
                            title
                            partnerBoxes {
                                id
                                title
                                logoImage {
                                    file {
                                        url
                                    }
                                }
                                description {
                                    description
                                }
                            }
                        }
                        leftChevron {
                            file {
                                url
                            }
                        }
                        rightChevron {
                            file {
                                url
                            }
                        }
                    }
                    ... on ContentfulSectionContact {
                        id
                        name
                        title
                        subtitleText: subtitle {
                            subtitle
                        }
                        profilePictures {
                            id
                            name
                            altText
                            profilePictureImage {
                                file {
                                    url
                                }
                            }
                        }
                        contactLinks {
                            ...ContactLink
                        }
                        legacyImage {
                            file {
                                url
                            }
                            fixed(width: 1440, quality: 90) {
                                width
                                height
                                src
                            }
                            description
                        }
                    }
                }
            }
        }
    }
`;

export default QuickJump;
